import { createFocusTrap } from 'focus-trap';
import StickySidebar from 'sticky-sidebar';

function handleSticky(mql) {
  if (mql.matches) {
    window.stickySidepanel = window.stickySidepanel || new StickySidebar('.app-sidebar__sticky-wrap', {
      containerSelector: '.app',
      innerWrapperSelector: '.app-sidebar__sticky-inner-container',
      resizeSensor: true,
    });
  } else if (window.stickySidepanel) {
    window.stickySidepanel.destroy();
    window.stickySidepanel = null;
  }
}

class NavPanel {
  constructor({
    // Default options.
    panelEl = '.nav-panel',
    showEl = '[data-action="nav-panel-show"]',
    closeEl = '[data-action="nav-panel-close"]',
    toggleEl = '[data-action="nav-panel-toggle"]',
    backdropEl = '.nav-panel__backdrop',
    breakpoint = 960,
  } = {}) {
    this.panelEl = document.querySelector(panelEl);
    this.showEl = document.querySelector(showEl);
    this.closeEl = document.querySelector(closeEl);
    this.toggleEl = document.querySelector(toggleEl);
    this.backdropEl = document.querySelector(backdropEl);
    this.breakpointQuery = `(min-width: ${breakpoint}px)`;

    this.mql = window.matchMedia(this.breakpointQuery);

    if (!this.mql.matches) {
      // Hide initially on mobile
      this.close();
    }

    handleSticky(this.mql);
    this.mql.addListener((mql) => {
      handleSticky(mql);
    });

    //this.focusTrap = focusTrap(this.panelEl, {
    this.focusTrap = createFocusTrap(this.panelEl, {
      clickOutsideDeactivates: true,
    });
  }

  show() {
    this.panelEl.setAttribute('aria-hidden', false);
    this.toggleEl.setAttribute('aria-expanded', true);
    document.body.classList.add('nav-panel--expanded');
    if (this.mql.matches) {
      document.body.classList.remove('overflow-hidden');
    } else {
      document.body.classList.add('overflow-hidden');
    }
  }

  close() {
    this.panelEl.setAttribute('aria-hidden', true);
    this.toggleEl.setAttribute('aria-expanded', false);
    document.body.classList.remove('nav-panel--expanded');
  }

  toggle() {
    if (this.panelEl.getAttribute('aria-hidden') === 'true') {
      this.show();
    } else {
      this.close();
    }
  }

  handleBreakpoint() {
    this.panelEl.style.transition = 'none';
    if (this.mql.matches) {
      this.show();
    } else {
      this.close();
    }
    requestAnimationFrame(() => {
      this.panelEl.style.transition = '';
    });
  }

  registerEvents() {
    if (this.showEl) this.showEl.addEventListener('click', () => { this.show(); });
    if (this.closeEl) this.closeEl.addEventListener('click', () => { this.close(); });
    if (this.backdropEl) this.backdropEl.addEventListener('click', () => { this.close(); });
    if (this.toggleEl) this.toggleEl.addEventListener('click', () => { this.toggle(); });

    this.mql.addListener(() => { this.handleBreakpoint(); });

    document.addEventListener('keyup', (event) => {
      if (event.code === 27 && !this.mql.matches) {
        this.close();
      }
    });

    this.panelEl.addEventListener('transitionend', (event) => {
      if (event.eventPhase === Event.AT_TARGET && event.propertyName === 'visibility') {
        if (event.target.getAttribute('aria-hidden') === 'true') {
          this.focusTrap.deactivate();
          document.body.classList.remove('overflow-hidden');
        } else {
          this.focusTrap.activate();
        }
      }
    });

    // Sticky sidebar doesn't calculate ending point correctly with lazy-loaded images. Workaround.
    jQuery('img').on('load', () => {
      window.stickySidepanel && window.stickySidepanel.updateSticky();
    });
  }

  mount() {
    if (this.panelEl) {
      this.registerEvents();
      this.handleBreakpoint();
    }
  }
}

function init() {
  // TODO: This should be consistent with the breakpoint in CSS.
  new NavPanel({
    breakpoint: 960,
  }).mount();
}

export default {
  init,
};
