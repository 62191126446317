import 'intersection-observer';
import googleMapsApiLoader from 'google-maps-api-loader';

function loadMap(googleApi, mapEl) {
  const mapCanvasEl = mapEl.querySelector('.b-google-map__canvas') || [];
  const markerEls = mapEl.querySelectorAll('data');

  const initialCenter = {
    lat: Number(markerEls[0].dataset.lat),
    lng: Number(markerEls[0].dataset.lng),
  };

  const bounds = new googleApi.maps.LatLngBounds();
  const infowindow = new googleApi.maps.InfoWindow();
  const map = new googleApi.maps.Map(mapCanvasEl, {
    center: initialCenter,
    zoom: 13,
    disableDefaultUI: true,
    gestureHandling: 'none',
    zoomControl: false,
  });

  markerEls.forEach((markerEl) => {
    const marker = new googleApi.maps.Marker({
      position: {
        lat: Number(markerEl.dataset.lat),
        lng: Number(markerEl.dataset.lng),
      },
      title: markerEl.textContent,
      // map,
    });

    marker.setMap(map);

    bounds.extend(marker.getPosition());

    marker.addListener('click', () => {
      infowindow.setContent(markerEl.textContent);
      infowindow.open(map, marker);
    });

    return marker;
  });

  googleApi.maps.event.addListenerOnce(map, 'bounds_changed', () => {
    if (map.getZoom() > 13) {
      map.setZoom(13);
    }
  });

  map.fitBounds(bounds);
}

function maybeLoad(googleApi, el) {
  const hasCookieConsent = window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.statistics;
  const inView = el.classList.contains('in-view');
  const isLoaded = el.classList.contains('is-loaded');
  if (!isLoaded && inView && hasCookieConsent) {
    el.classList.add('is-loaded');
    loadMap(googleApi, el);
  }
}

function init() {
  const mapEls = document.querySelectorAll('.b-google-map') || [];

  if (!mapEls.length) {
    return;
  }

  const { apiKey } = mapEls[0].querySelector('.b-google-map__canvas').dataset;

  const waitForConsent = new Promise((resolve) => {
    window.addEventListener('CookiebotOnConsentReady', () => {
      const hasCookieConsent = window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.statistics;
      if (hasCookieConsent) {
        resolve();
      }
    });
  });

  // Wait for Cookie Consent
  waitForConsent
    .then(() => (
      // Load Google maps
      googleMapsApiLoader({
        libraries: ['places'],
        apiKey,
      }, (err) => {
        console.error(err);
      })
    ))
    .then((googleApi) => {
      // Initialize maps
      mapEls.forEach((el) => {
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              el.classList.add('in-view');
              maybeLoad(googleApi, el);
            } else {
              el.classList.remove('in-view');
            }
          });
        }, { threshold: 0 });
        observer.observe(el);
      });
    });
}

export default {
  init,
};
