import Swiper from 'swiper';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/bundle';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/a11y';

const btnPrev = document.querySelector('.swiper-button-prev');
const btnNext = document.querySelector('.swiper-button-next');

function updateTextColor(obj) {
  const { slides } = obj;
  const currentIndex = obj.swiper.activeIndex;
  const currentSlideEl = slides[currentIndex];
  const currentTextColor = currentSlideEl.querySelector('.b-carousel-item').dataset.textColor;
  slides[currentIndex].closest('.b-carousel').setAttribute('data-current-text-color', currentTextColor);
}


function mountCarousels(carouselEls) {
  const sliders = [];

  Swiper.use([Navigation, Pagination, A11y, Autoplay]);

  [...carouselEls].forEach((carouselEl, idx) => {
    const slides = carouselEl.querySelectorAll('.swiper-slide');
    const videos = carouselEl.querySelectorAll('.swiper-slide video');
    const btnPlayPause = carouselEl.querySelector('.swiper-autoplay-toggle');

    const swiper = new Swiper(carouselEl.querySelector('.swiper-container'), {
      // simulateTouch: false,
      speed: 1000,
      autoplay: {
        delay: 10000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletElement: 'button',
      },
      navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev',
       },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      // autoHeight: true,
      init: () => {
        updateTextColor(sliders[idx]);
      },
    });

    swiper.on('slideChange', () => {
      // updateShortcuts();
      // toggleVideos(sliders[idx]);
      updateTextColor(sliders[idx]);
    });

    swiper.on('slideChange', () => {
      // updateShortcuts();
      // toggleVideos(sliders[idx]);
      updateTextColor(sliders[idx]);
    });

    //btnPrev.addEventListener('click', () => swiper.slidePrev());
    //btnNext.addEventListener('click', () => swiper.slideNext());

    /*
    btnPlayPause.addEventListener('click', () => {
      if (swiper.autoplay.running) {
        swiper.autoplay.stop();
        btnPlayPause.setAttribute('aria-pressed', false);
      } else {
        swiper.autoplay.start();
        btnPlayPause.setAttribute('aria-pressed', true);
      }
    });
    */

    sliders[idx] = {
      swiper,
      slides,
      videos,
    };
  });
}

function init() {
  const carouselEls = document.querySelectorAll('.b-carousel');
  if (carouselEls.length) {
    mountCarousels(carouselEls);
    // if (btnScrollToNextBlock) {
    //   registerButtonEvents();
    // }
  }
}

export default {
  init,
};
