// Modern Javascript file for new browsers.
// Build will include less polyfills etc than "legacy" version.

import 'babel-polyfill';
import 'intersection-observer';
import 'focus-visible';
import 'element-qsa-scope';
import 'alpinejs';

import focusWithinPolyfill from 'focus-within';
import smoothScrollPolyfill from 'smoothscroll-polyfill';
import tippy from 'tippy.js';

import resizeSensor from './lib/resizesensor';

import navPanel from '../../components/nav-panel/nav-panel';
import topBar from '../../components/top-bar/top-bar';
// import lazyLoad from '../../components/lazy-load/lazy-load';
import gravityForms from '../../components/gravity-forms/gravity-forms';
import carousel from '../../blocks/carousel/carousel';
import googleMap from '../../blocks/google-map/google-map';
import bookingForm from '../../blocks/booking-form/booking-form';
// import postListFilters from '../../blocks/post-list-filters/post-list-filters';
// import { debounce } from 'lodash-es';

const appStart = () => {

  window.$ = window.jQuery;

  focusWithinPolyfill(document);
  smoothScrollPolyfill.polyfill();

  navPanel.init();
  topBar.init();
  // lazyLoad.init();
  gravityForms.init();
  carousel.init();
  googleMap.init();
  bookingForm.init();
  // postListFilters.init();


  const venueListItemEls = document.querySelectorAll('.js-venue-table');

  if (venueListItemEls) {
    tippy('[data-tippy-content]');
  }
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}
