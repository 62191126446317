/* global jQuery */

function init() {
  if (document.documentElement.lang === 'fi') {
    jQuery.extend( jQuery.fn.pickadate.defaults, {
      monthsFull: ['tammikuu', 'helmikuu', 'maaliskuu', 'huhtikuu', 'toukokuu', 'kesäkuu', 'heinäkuu', 'elokuu', 'syyskuu', 'lokakuu', 'marraskuu', 'joulukuu'],
      monthsShort: ['tammi', 'helmi', 'maalis', 'huhti', 'touko', 'kesä', 'heinä', 'elo', 'syys', 'loka', 'marras', 'joulu'],
      weekdaysFull: ['sunnuntai', 'maanantai', 'tiistai', 'keskiviikko', 'torstai', 'perjantai', 'lauantai'],
      weekdaysShort: ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la' ],
      labelMonthNext: 'Seuraava kuukausi',
      labelMonthPrev: 'Edellinen kuukausi',
      labelMonthSelect: 'Valitse kuukausi',
      labelYearSelect: 'Valitse vuosi',
      today: 'Tänään',
      clear: 'Tyhjennä',
      close: 'Sulje',
      firstDay: 1,
      format: 'd.m.yyyy',
      formatSubmit: 'yyyy-mm-dd',
      hiddenName: true,
    });
  } else {
    jQuery.extend( jQuery.fn.pickadate.defaults, {
      firstDay: 1,
      format: 'd.m.yyyy',
      formatSubmit: 'yyyy-mm-dd',
      hiddenName: true,
    });
  }

  jQuery('.datepicker').pickadate();
}

export default {
  init,
};
